import { useState, useEffect } from "react"
import { useMapEvents } from "react-leaflet"
import { type Layer, Util } from "leaflet"
import { groupBy } from "lodash"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import LayersIcon from "@mui/icons-material/Layers"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import createControlledLayer from "./ControlledLayer"
import { LayersControlProvider } from "./CustomLayerControlsContext"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Typography,
  Box,
  Button,
  Stack,
  Tooltip,
} from "@mui/material"

const POSITION_CLASSES: { [key: string]: string } = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
}

interface IProps {
  children: any
  position: string
}

interface ILayerObj {
  layer: Layer
  group: string
  name: string
  checked: boolean
  id: number
  icon?: string // For feature layers
}

const LayerControl = ({ position, children }: IProps) => {
  const [collapsed, setCollapsed] = useState(true)
  const [layers, setLayers] = useState<ILayerObj[]>([])
  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
  const map = useMapEvents({})

  const onLayerClick = (layerObj: ILayerObj) => {
    const newLayers = layers.map((layer) => {
      if (layer.id === layerObj.id) {
        const checked = !layer.checked
        if (checked && map) map.addLayer(layer.layer)
        else if (map) map.removeLayer(layer.layer)
        return { ...layer, checked }
      }
      // For base layers, ensure only one is selected
      if (layer.group === "Base Layers") {
        const checked = layerObj.group === "Base Layers" ? layer.id === layerObj.id : layer.checked
        if (checked && map) map.addLayer(layer.layer)
        else if (map) map.removeLayer(layer.layer)
        return { ...layer, checked }
      }
      return layer
    })
    setLayers(newLayers)
  }

  const toggleAllFeatureLayers = (show: boolean) => {
    const newLayers = layers.map((layer) => {
      if (layer.group === "Feature Layers") {
        if (show && map) map.addLayer(layer.layer)
        else if (map) map.removeLayer(layer.layer)
        return { ...layer, checked: show }
      }
      return layer
    })
    setLayers(newLayers)
  }

  const onGroupAdd = (layer: Layer, name: string, group: string, icon?: string) => {
    const id = Util.stamp(layer)
    const checked = map && map.hasLayer(layer)
    setLayers((prevLayers) => [...prevLayers, { layer, group, name, checked, id, icon }])
  }

  const groupedLayers = groupBy(layers, "group")

  const renderBaseLayer = (layerObj: ILayerObj) => (
    <Box
      onClick={() => onLayerClick(layerObj)}
      sx={{
        cursor: "pointer",
        p: 0.5,
        border: (theme) => `1px solid ${layerObj.checked ? theme.palette.primary.main : theme.palette.grey[300]}`,
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 0.5,
        width: 70,
      }}>
      <Box
        sx={{
          width: 60,
          height: 60,
          backgroundImage: `url(${layerObj.icon || "/api/placeholder/70/70"})`,
          backgroundSize: "cover",
          borderRadius: 0.5,
        }}
      />
      <Typography variant="caption" sx={{ fontSize: 11 }}>
        {layerObj.name}
      </Typography>
    </Box>
  )

  const renderFeatureLayer = (layerObj: ILayerObj) => (
    <FormControlLabel
      sx={{
        m: 0,
        py: 0.5,
        width: "100%",
        "& .MuiTypography-root": {
          fontSize: 13,
        },
      }}
      control={
        <Checkbox checked={layerObj.checked} onChange={() => onLayerClick(layerObj)} size="small" sx={{ p: 0.5 }} />
      }
      label={
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {layerObj.icon && <Box component="img" src={layerObj.icon} sx={{ width: 16, height: 16 }} />}
          {layerObj.name}
        </Box>
      }
    />
  )

  return (
    <LayersControlProvider
      value={{
        layers,
        addGroup: onGroupAdd,
      }}>
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar">
          <Paper onMouseEnter={() => setCollapsed(false)} onMouseLeave={() => setCollapsed(true)}>
            {collapsed ? (
              <IconButton>
                <LayersIcon />
              </IconButton>
            ) : (
              <Box sx={{ p: 1, minWidth: 200, maxWidth: 280, overflow: "hidden" }}>
                {Object.keys(groupedLayers).map((section, index) => (
                  <Accordion
                    key={`${section} ${index}`}
                    disableGutters
                    elevation={0}
                    sx={{
                      "&:before": { display: "none" },
                      border: 0,
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        minHeight: 36,
                        p: 0,
                        "& .MuiAccordionSummary-content": {
                          m: 0,
                        },
                      }}>
                      <Typography variant="subtitle2">{section}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0.5 }}>
                      {section === "Base Layers" ? (
                        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap", gap: 1 }}>
                          {groupedLayers[section]?.map((layerObj: ILayerObj, index: number) => (
                            <Box key={`baseLayer_${index}`}>{renderBaseLayer(layerObj)}</Box>
                          ))}
                        </Stack>
                      ) : (
                        <Box>
                          <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                            <Button
                              size="small"
                              onClick={() => toggleAllFeatureLayers(true)}
                              startIcon={<VisibilityIcon fontSize="small" />}
                              variant="outlined"
                              sx={{
                                textTransform: "none",
                                borderRadius: 1,
                                py: 0.5,
                                fontSize: "0.8125rem",
                              }}>
                              Show All
                            </Button>
                            <Button
                              size="small"
                              onClick={() => toggleAllFeatureLayers(false)}
                              startIcon={<VisibilityOffIcon fontSize="small" />}
                              variant="outlined"
                              sx={{
                                textTransform: "none",
                                borderRadius: 1,
                                py: 0.5,
                                fontSize: "0.8125rem",
                              }}>
                              Hide All
                            </Button>
                          </Stack>
                          <Stack spacing={0.5}>
                            {groupedLayers[section]?.map((layerObj: ILayerObj, index: number) => (
                              <Box key={`featureLayer_${index}`}>{renderFeatureLayer(layerObj)}</Box>
                            ))}
                          </Stack>
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )}
          </Paper>
        </div>
        {children}
      </div>
    </LayersControlProvider>
  )
}

const GroupedLayer = createControlledLayer((layersControl, layer, name, group, icon) => {
  layersControl.addGroup(layer, name, group, icon)
})

export default LayerControl
export { GroupedLayer }
