export const BASE_API_URL = process.env.REACT_APP_API_URL || ""
export const BASE_API_WS_URL = process.env.REACT_APP_API_WS_URL || ""
export const BASE_API_LISTENER_URL = process.env.REACT_APP_API_LISTENER_URL || ""
export const BASE_ESRI_API_KEY = process.env.REACT_APP_ESRI_API_KEY || ""
export const BASE_ESRI_API_URL = process.env.REACT_APP_ESRI_API_URL || ""
export const BASE_MAPBOX_API_TOKEN = process.env.REACT_APP_MAPBOX_API_TOKEN || ""
export const BASE_ORG_ID = process.env.REACT_APP_ORG_ID || ""
export const WORKSPACE = "MapSource"
export const MAX_IMAGE_SIZE: number = Number.parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE ?? "2097152", 10) || 2097152
export const BLOB_CHUNK_SIZE = Number.parseInt(process.env.REACT_APP_BLOB_CHUNK_SIZE ?? "4194304", 10) || 4194304
export const AZURE_BACKUP_PATH = "watermelon-exports"
export const UPLOADS_DB_NAME = "UploadDB"
