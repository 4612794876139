import { LeafletProvider, useLeafletContext } from "@react-leaflet/core"
import type { Layer } from "leaflet"
import React, { useCallback, useEffect, useMemo, useRef, useState, type ReactNode } from "react"
import { useLayerControlContext } from "./CustomLayerControlsContext"
import { useMap } from "react-leaflet"

interface IProps {
  checked?: boolean
  name: string
  group: string
  children: ReactNode[] | ReactNode
  icon: any
}

const createControlledLayer = (
  addLayerToControl: (layerContext: any, layer: Layer, name: string, group: string, icon: any) => any,
) => {
  const ControlledLayer = (props: IProps) => {
    const context = useLeafletContext()
    const layerContext = useLayerControlContext()
    const propsRef = useRef(props)
    const parentMap = useMap()

    const [layer, setLayer] = useState<Layer | null>(null)

    const addLayer = useCallback(
      (layerToAdd: any) => {
        if (propsRef.current.checked) {
          parentMap.addLayer(layerToAdd)
        }

        addLayerToControl(
          layerContext,
          layerToAdd,
          propsRef.current.name,
          propsRef.current.group,
          propsRef.current.icon,
        )
        setLayer(layerToAdd)
      },
      [context],
    )

    const removeLayer = useCallback(
      (layerToRemove: any) => {
        if (context.layersControl) context.layersControl?.removeLayer(layerToRemove)
        setLayer(null)
      },
      [context],
    )

    const newContext = useMemo(() => {
      return context
        ? Object.assign({}, context, {
            layerContainer: {
              addLayer,
              removeLayer,
            },
          })
        : null
    }, [context, addLayer, removeLayer])

    useEffect(() => {
      if (layer !== null && propsRef.current !== props) {
        if (props.checked === true && (propsRef.current.checked === null || propsRef.current.checked === false)) {
          parentMap.addLayer(layer)
        } else if (propsRef.current.checked === true && (props.checked === null || props.checked === false)) {
          parentMap.removeLayer(layer)
        }

        propsRef.current = props
      }

      return () => {
        if (layer !== null && context.layersControl) {
          context.layersControl.removeLayer(layer)
        }
      }
    })

    return props.children
      ? React.createElement(
          LeafletProvider,
          {
            value: newContext,
          },
          props.children,
        )
      : null
  }

  return ControlledLayer
}

export default createControlledLayer
