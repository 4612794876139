import { Suspense, lazy, useEffect, useState } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { EventType, type IPublicClientApplication } from "@azure/msal-browser"
import { CustomNavigationClient } from "./utils/navigationClient.util"
import { createSignalRContext } from "react-signalr/signalr"

import { DatabaseProvider } from "@nozbe/watermelondb/DatabaseProvider"
import { database } from "./model/database"

import DashboardLayout from "./layouts/Dashboard.layout"
import { setupInterceptors, getAuthToken } from "./services/api.service"
import { BASE_API_WS_URL, WORKSPACE } from "./constants"
import { userService } from "./services"
import { useLocalStorage } from "usehooks-ts"
import ScrollToTop from "./components/Atoms/ScrollToTop/ScrollToTop.component"
import { msalInstance } from "."
import type { ISettings } from "./contexts/settings.context"
import { LicenseInfo } from "@mui/x-license"
import { SyncProvider } from "./providers/SyncProvider"
import { securityService } from "./services/security.service"
import { type IUser, PermissionBasedRoutes } from "@utilisourcepackagelibdev/utilisourcepackagelib"
import * as Sentry from "@sentry/react"
import { transitions, positions, Provider as AlertProvider } from "react-alert"
import { Alert } from "@mui/material"

type AppProps = {
  pca: IPublicClientApplication
}

const SignalRContext = createSignalRContext()

//? Lazy Loaded Routes
const Home = lazy(async () => import("./pages/Home/Home.page"))
const FormsPage = lazy(async () => import("./pages/Forms/Forms.page"))
const FormBuilderPage = lazy(async () => import("./pages/FormBuilder/FormBuilder.page"))
const FormRendererPage = lazy(async () => import("./pages/FormRenderer/FormRenderer.page"))
const SurveysPage = lazy(async () => import("./pages/Surveys/Surveys.page"))
const FeaturesPage = lazy(async () => import("./pages/Features/Features.page"))
const ReportsPage = lazy(async () => import("./pages/Reports/Reports.page"))
const SurveyDetailPage = lazy(async () => import("./pages/Surveys/SurveyDetail2.page"))
const PointsPage = lazy(async () => import("./pages/Points/Points.page"))
const WorldPage = lazy(async () => import("./pages/World/World.page"))
const WorldsPage = lazy(async () => import("./pages/Worlds/Worlds.page"))
const ProjectsPage = lazy(async () => import("./pages/Projects/Projects.page"))
const UsersPage = lazy(async () => import("./pages/Users/Users.page"))
const LogInPage = lazy(async () => import("./pages/LogIn/LogIn.page"))
const PermissionsPage = lazy(async () => import("./pages/Permissions/Permissions.page"))
const ProjectMapPage = lazy(async () => import("./pages/Projects/ProjectMap.page"))
const DatabaseMetricsPage = lazy(async () => import("./pages/Settings/DatabaseMetrics.page"))

function App({ pca }: AppProps) {
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  const [_user, setUser] = useLocalStorage<IUser | null>("user", null)
  const [activeAccount, setActiveAccount] = useState(pca.getActiveAccount())
  const [token, setToken] = useState<string>("")
  const [_settings, setSettings] = useLocalStorage<ISettings | null>("settings", null)

  pca.setNavigationClient(navigationClient)
  setupInterceptors()

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || "")

  useEffect(() => {
    // This will be run on component mount
    const callbackId = msalInstance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload
        console.log(result)
        setActiveAccount(pca.getActiveAccount())
      }
    })

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId)
      }
    }
  }, [])

  useEffect(() => {
    async function getToken() {
      const { token, user } = await getAuthToken()
      if (user && user.email) {
        // Checks if we have an exiting user
        const exist = await securityService.getUser(user.email)
        if (exist) setUser(exist)
        else await userService.addUser(user)

        console.log("Sentry User", user)
        Sentry.setUser({
          username: user.userName,
          email: user.email,
        })
      }
      setToken(token)
    }
    getToken()
  }, [activeAccount])

  useEffect(() => {
    // If sidebar value inside localstorage has a cached node value,
    // this will throw an error.
    // We need to check and clear it.
    if (_settings && _settings.sidebar) {
      setSettings({
        ..._settings,
        sidebar: null,
      })
    }
  }, [_settings?.sidebar])

  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.FADE,
  }

  return (
    <MsalProvider instance={pca}>
      <AlertProvider
        {...options}
        template={({ message, style, close }) => (
          <Alert onClose={close} style={{ ...style }}>
            {message}
          </Alert>
        )}>
        <DatabaseProvider database={database}>
          <ScrollToTop />

          <div id="App">
            <AuthenticatedTemplate>
              <SyncProvider>
                <Suspense fallback={<div>Loading...</div>}>
                  <SignalRContext.Provider
                    connectEnabled={!!token && token.length > 0}
                    automaticReconnect={true}
                    accessTokenFactory={() => token}
                    dependencies={[token]} //remove previous connection and create a new connection if changed
                    logMessageContent={false}
                    url={BASE_API_WS_URL + "/survey-hub"}>
                    <Routes>
                      <Route path={"/"} element={<DashboardLayout />}>
                        <Route index element={<Home />} />

                        <Route path="/dashboard" element={<PermissionBasedRoutes user={_user} workspace={WORKSPACE} />}>
                          <Route path={"worlds"} element={<WorldsPage />} />
                          <Route path={"projects"} element={<ProjectsPage />} />
                          <Route path={"users"} element={<UsersPage />} />
                          <Route path="templates">
                            <Route index element={<FormsPage />} />
                            <Route path={`:formId`} element={<FormBuilderPage />} />
                          </Route>
                        </Route>

                        <Route path="/permissions" element={<PermissionsPage />} />

                        <Route path={`/project`}>
                          <Route index element={<Home />} />
                          <Route path={`map`} element={<ProjectMapPage />} />
                          <Route path="jobs">
                            <Route index element={<SurveysPage />} />
                            <Route path={`:id`} element={<SurveyDetailPage />} />
                          </Route>
                          <Route path="points" element={<PointsPage />} />
                          <Route path="features" element={<FeaturesPage />} />
                          <Route path="reports" element={<ReportsPage />} />
                        </Route>

                        <Route path={"/forms"}>
                          <Route index element={<FormsPage />} />
                          <Route path={`builder/:id`} element={<FormBuilderPage />} />
                          <Route path={`renderer/:id`} element={<FormRendererPage />} />
                        </Route>
                        <Route path={"/features"}>
                          <Route index element={<FeaturesPage />} />
                        </Route>
                        <Route path={"/settings"}>
                          <Route index path={`database`} element={<DatabaseMetricsPage />} />
                        </Route>

                        <Route path={"/points"} element={<PointsPage />} />

                        <Route path={`world/:step?`} element={<WorldPage />} />
                        <Route path={"/worlds"} element={<WorldsPage />} />
                      </Route>
                    </Routes>
                  </SignalRContext.Provider>
                </Suspense>
              </SyncProvider>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <LogInPage />
            </UnauthenticatedTemplate>
          </div>
        </DatabaseProvider>
      </AlertProvider>
    </MsalProvider>
  )
}

export { SignalRContext }

export default App
