import React, { useState } from "react"
import { Stack, Table, TableBody, TableContainer, Typography, Button } from "@mui/material"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import { styled } from "@mui/material/styles"
import TableRow from "@mui/material/TableRow"

interface PointData {
  [key: string]: string | number | null
  FID: number
  Created: string
  Frequency: number
  Direction: number
  Current: string
  Depth: string
  Latitude: number
  Longitude: number
  "RMS 2D": number
  Fix: number
}

interface PointDataTableProps {
  displayProps?: {
    properties: {
      pointData: PointData
    }
  }
}

const PRIORITY_FIELDS = [
  "FID",
  "Created",
  "Frequency",
  "Direction",
  "Current",
  "Depth",
  "Latitude",
  "Longitude",
  "RMS 2D",
  "Fix",
]

export const PointDataTable: React.FC<PointDataTableProps> = ({ displayProps }) => {
  const [showMore, setShowMore] = useState(false)

  if (!displayProps?.properties.pointData) return null

  const pointData = displayProps.properties.pointData

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  const renderTableRow = (key: string, value: string | number) => (
    <StyledTableRow key={key}>
      <StyledTableCell>
        <Stack>
          <Typography fontSize={11} fontWeight={700}>
            {key}
          </Typography>
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="right">{value ? value.toString() : ""}</StyledTableCell>
    </StyledTableRow>
  )

  // Filter and sort priority fields
  const priorityEntries = PRIORITY_FIELDS.map((key) => [key, pointData[key]]).filter(
    ([_, value]) => value !== null,
  ) as [string, string | number][]

  // Filter remaining fields
  const remainingEntries = Object.entries(pointData).filter(
    ([key, value]) =>
      !PRIORITY_FIELDS.includes(key) && (typeof value === "string" || typeof value === "number") && value !== null,
  )

  return (
    <Stack gap={0.5} overflow="hidden" maxHeight={250} borderRadius={2} border="solid 0.5px gray">
      <TableContainer>
        <Table size="small">
          <TableBody>
            {/* Priority Fields */}
            {priorityEntries.map(([key, value]) => renderTableRow(key, value))}

            {/* Show More Button */}
            <StyledTableRow>
              <StyledTableCell colSpan={2} align="center">
                <Button onClick={() => setShowMore(!showMore)} size="small" sx={{ fontSize: 11 }}>
                  {showMore ? "Show Less" : "Show More"}
                </Button>
              </StyledTableCell>
            </StyledTableRow>

            {/* Remaining Fields */}
            {showMore && remainingEntries.map(([key, value]) => renderTableRow(key, value))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default PointDataTable
