import { BASE_API_URL } from "../constants/commonStrings.constant"
import type { ReportRequest } from "../interfaces/report.interface"
import { exportFile, getFileNameFromContentDisposition } from "../utils/data.util"
import { apiService } from "./api.service"

class ReportService {
  async getPointsReport(params: any): Promise<Blob> {
    const { formIds, projectIds, outputFormatId, fromDate, toDate } = params
    const reportRequest: ReportRequest = {
      reportType: "points-report",
      formIds,
      projectIds,
      outputFormatId,
      fromDate,
      toDate,
    }
    return this.getReport(reportRequest)
  }

  async getProjectFeaturesReport(params: any): Promise<Blob> {
    const { formIds, projectIds, outputFormatId, fromDate, toDate } = params
    const reportRequest: ReportRequest = {
      reportType: "project-data-feature-report",
      formIds,
      projectIds,
      outputFormatId,
      fromDate,
      toDate,
    }
    return this.getReport(reportRequest)
  }

  async getGeoJsonServiceReport(params: any): Promise<Blob> {
    const { formIds, projectId } = params
    const reportRequest: ReportRequest = {
      reportType: "geojson-service-report",
      formIds,
      projectId,
    }
    return this.getReport(reportRequest)
  }

  async getPointsReportKML(params: any): Promise<Blob> {
    const { formId, projectId } = params
    const reportRequest: ReportRequest = {
      reportType: "features-report-kml",
      formId,
      projectId,
    }
    return this.getReport(reportRequest)
  }

  async getProjectFormDataReport(params: any): Promise<Blob> {
    const {
      formIds,
      projectId,
      surveyIds,
      outputFormatId,
      fromDate,
      toDate,
      featureIds,
      includeImageTable,
      includeImageUrl,
      includeImageHtml,
    } = params
    const reportRequest: ReportRequest = {
      reportType: "project-data-report",
      formIds,
      projectId,
      surveyIds,
      featureIds,
      outputFormatId,
      fromDate,
      toDate,
      includeImageTable,
      includeImageUrl,
      includeImageHtml,
    }
    return this.getReport(reportRequest)
  }

  async getFeatureReport(params: any): Promise<Blob> {
    const { featureIds, featureId, formIds, projectId, fromDate, toDate } = params
    const reportRequest: ReportRequest = {
      reportType: "feature-details-report",
      featureIds,
      featureId,
      formIds,
      projectId,
      fromDate,
      toDate,
    }
    return this.getReport(reportRequest)
  }

  async getFeatureImagesDownload(params: any): Promise<Blob> {
    const { featureIds, projectId, fromDate, toDate } = params
    const reportRequest: ReportRequest = {
      reportType: "feature-images-download",
      featureIds,
      projectId,
      fromDate,
      toDate,
    }
    return this.getReport(reportRequest)
  }

  async getFeatureImagesDownloadForFeature(params: any): Promise<Blob> {
    const { featureId } = params
    const reportRequest: ReportRequest = {
      reportType: "feature-images-download",
      featureId,
    }
    return this.getReport(reportRequest)
  }

  async getReportFromFormData(params: any): Promise<Blob> {
    const { reportType, formData } = params
    const reportRequest: ReportRequest = {
      reportType,
      formData,
    }
    return this.getReport(reportRequest)
  }

  async getReport(request: ReportRequest): Promise<Blob> {
    try {
      const url = BASE_API_URL + `/report/generateReport`
      const response = await apiService({
        url,
        method: "POST",
        data: request,
        withCredentials: false,
        responseType: "blob", // Important to specify the response type
      })
      const contentDisposition = response.headers["content-disposition"]
      const filename = contentDisposition
        ? getFileNameFromContentDisposition(contentDisposition)
        : "errorGettingReportName.txt"

      exportFile(response.data, filename)
      return response.data
    } catch (error) {
      throw new Error("Error getting report")
    }
  }
}

export const reportService = new ReportService()
