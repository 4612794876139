import * as React from "react"
import Checkbox from "@mui/material/Checkbox"
import type { FormDataTypeProps } from "../../../interfaces"
import { FormControlLabel, FormGroup } from "@mui/material"

const CheckboxInput: React.FC<FormDataTypeProps> = ({ onDataChange, dataUrl, ...props }: FormDataTypeProps) => {
  const [value, setValue] = React.useState<boolean>(props.value ?? false)

  const handleOnChange = (e: React.SyntheticEvent, checked: boolean) => {
    setValue(checked)
    onDataChange(props.id, checked)
  }
  return (
    <FormGroup>
      <FormControlLabel
        onChange={handleOnChange}
        control={<Checkbox size="small" checked={value} />}
        label={props.label}
      />
    </FormGroup>
  )
}

export default CheckboxInput
